import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useRef } from 'react';
import Logo from '../assets/images/logo.png';
import HABH from '../assets/images/nabh.png';

const LetterHead = () => {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px'
                }}
            >
                <Box sx={{ marginRight: 5 }}>
                    <Card variant="outlined">
                        <img src={Logo} alt="company name" width="60px" />
                    </Card>
                </Box>

                <Box textAlign="center">
                    <Typography variant="h4">MATRUSEVA SUPERSPECIALITY CENTER</Typography>
                    <Typography sx={{ fontSize: '11px' }}>
                        Bhokare Colony, Near Siddhivinayak Cancer Hospital
                        <br />
                        Opp. Reliance Petrol Pump, Miraj - 416410, Ph: 0233-2212210
                    </Typography>
                </Box>

                <Box sx={{ marginRight: 5 }}>
                    <Card variant="outlined">
                        <img src={HABH} alt="company name" width="60px" />
                    </Card>
                </Box>
            </Box>

            <Divider sx={{ borderBottomWidth: 1, mt: 1, mb: 2, bgcolor: '#000000' }} />
        </Box>
    );
};

export default LetterHead;
