import React from 'react';

//MUI Imports
import { useTheme } from '@mui/material/styles';

//Project Imports
import LetterHead from 'ui-component/LetterHead';
import { Card, Divider, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import calculateAge from 'utils/calculateAge';

const InvestigationsPrint = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const { patientData, investigationData, totalAmount, doctorName } = props;

    const getPageMargins = () => {
        return `@page { margin: ${'40px'} ${'40px'} ${'40px'} ${'40px'} !important; }`;
    };
    const pageStyle = '@page {size: A4 portrait;}';
    return (
        <div ref={ref}>
            <style>{getPageMargins()}</style>
            <div style={{ position: 'fixed', top: '0', width: '100%' }}>
                <LetterHead />
            </div>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <td>
                            <div style={{ height: '90px' }}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div align="center">
                                <Typography variant="h4">Suggested Investigations</Typography>
                            </div>
                            {patientData != undefined ? (
                                <Grid container direction="row" sx={{ paddingX: 1, mt: 3 }}>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            UHID
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }}>: {patientData.uhid}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            Patient Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }}>
                                            : {patientData.patientMaster.firstName} {patientData.patientMaster.middleName}{' '}
                                            {patientData.patientMaster.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            Patient Age:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }}>: {calculateAge(patientData.patientMaster.birthDate)}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            Patient Gender:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }}>: {patientData.patientMaster.genderDisplay}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            Patient Address:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }}>
                                            : {patientData.patientMaster.address}, {patientData.patientMaster.city}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            Mobile No.:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }}>: {patientData.patientMaster.mobileNumber}</Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} align="left">
                                        <Typography variant="h5" sx={{ mb: '3px' }}>
                                            Doctor's Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} align="left">
                                        <Typography sx={{ mb: '3px' }} variant="h5">
                                            : {doctorName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {investigationData != undefined ? (
                                <>
                                    <TableContainer sx={{ mt: 3 }}>
                                        <Table size="small" sx={{ border: 'solid', borderWidth: 1, borderColor: 'divider' }}>
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableRow>
                                                    <TableCell>Sr. No.</TableCell>
                                                    <TableCell>Investigation Name</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="left">Charges</TableCell>
                                                    {/* <TableCell align="left">Status</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {investigationData?.map((row, index) => (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">{row.typeDisplay}</TableCell>
                                                        <TableCell align="left">{row.charges}</TableCell>
                                                        {/* <TableCell align="left">
                                                            {row.status === 0 && (
                                                                <Typography variant="h5" color="error">
                                                                    {row.statusDisplay}
                                                                </Typography>
                                                            )}
                                                            {row.status === 1 && (
                                                                <Stack>
                                                                    <Typography variant="h5" color="primary">
                                                                        {row.statusDisplay}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        Collected By: {row.paymentCollectedUserName}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        On: {new Date(row.paymentDate).toLocaleDateString('hi-IN')}
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                            {row.status === 2 && (
                                                                <Stack>
                                                                    <Typography variant="h5" color="success.dark">
                                                                        {row.statusDisplay}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        Completed By: {row.completedByUserName}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        On: {new Date(row.completionDate).toLocaleDateString('hi-IN')}
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                            {row.status === 3 && (
                                                                <Typography variant="h5" color="secondary">
                                                                    {row.statusDisplay}
                                                                </Typography>
                                                            )}
                                                        </TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid sx={{ mt: 2 }}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="h5" sx={{ mb: '3px' }}>
                                                Total Amount:
                                            </Typography>
                                            <Typography sx={{ mb: '3px' }}>₹ {totalAmount}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid align="right" sx={{ mt: 2 }}>
                                        <Typography variant="h5" sx={{ mt: 5 }}>
                                            Authorised Signature
                                        </Typography>
                                    </Grid>
                                </>
                            ) : null}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
});

export default InvestigationsPrint;
