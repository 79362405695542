import React from 'react';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { handleResponse, handleError } from './response';

const useInvestigationItemService = () => {
    const axiosAuth = useAxiosAuth();

    const getInvestigationItemsById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvestigationItemCountListByStatus = async (status) => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems/count-by-patient?status=${status}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvestigationItemsListByAppointmentId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems/filterd-list?appointmentDetailsId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvestigationItemsListByPatientId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems/filterd-list?patientId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvestigationItemsListByStatus = async (status) => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems/filterd-list?status=${status}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvestigationItemsListByPatientAndStatus = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems/filterd-list?patientId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvesitationCountByPatient = async (status) => {
        try {
            if (status == undefined) {
                const response = await axiosAuth.get(`/api/InvestigationItems/count-by-patient`);
                return handleResponse(response);
            } else {
                const response = await axiosAuth.get(`/api/InvestigationItems/count-by-patient?status=${status}`);
                return handleResponse(response);
            }
        } catch (error) {
            return handleError(error);
        }
    };
    const getInvestigationItemsList = async () => {
        try {
            const response = await axiosAuth.get(`/api/InvestigationItems`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addInvestigationItems = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/InvestigationItems`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateInvestigationItems = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/InvestigationItems?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteInvestigationItems = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/InvestigationItems?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const statusUpdateInvestigationItems = async (id, status, userId, amount) => {
        try {
            const response = await axiosAuth.put(
                `/api/InvestigationItems/update-status?id=${id}&status=${status}&userId=${userId}&amount=${amount}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const statusUpdateWithAmountInvestigationItems = async (id, status, userId, amount) => {
        try {
            const response = await axiosAuth.put(
                `/api/InvestigationItems/update-status?id=${id}&status=${status}&userId=${userId}&amount=${amount}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        getInvestigationItemsById,
        getInvestigationItemCountListByStatus,
        getInvestigationItemsListByAppointmentId,
        getInvestigationItemsListByPatientId,
        getInvestigationItemsListByPatientAndStatus,
        getInvesitationCountByPatient,
        getInvestigationItemsList,
        addInvestigationItems,
        updateInvestigationItems,
        deleteInvestigationItems,
        statusUpdateInvestigationItems,
        statusUpdateWithAmountInvestigationItems
    };
};

export default useInvestigationItemService;
