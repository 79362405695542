import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { useSearchParams } from 'react-router-dom';

//MUI Imports
import {
    Card,
    CardHeader,
    Divider,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
    Button,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Snackbar,
    Alert,
    CardContent,
    TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import useInvestigationItemService from 'services/useInvestigationItemService';
import usePatientsService from 'services/usePatientsService';
import Report from 'views/patients/patient-details/Report';
import calculateAge from 'utils/calculateAge';
import InvestigationsPrint from './InvestigationsPrint';

//Icon Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import CommentIcon from '@mui/icons-material/Comment';

//Third Party Imports
import { useReactToPrint } from 'react-to-print';

const InvestigationDetails = () => {
    const theme = useTheme();
    const { getInvestigationItemsListByPatientAndStatus, statusUpdateInvestigationItems, updateInvestigationItems } =
        useInvestigationItemService();
    const { getPatientById } = usePatientsService();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { userId, userName, role, displayRole, profileId } = useSelector((state) => state.userReducer);

    //States

    const [openPaidDialog, setOpenPaidDialog] = useState(false);
    const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openCommentDialog, setOpenCommentDialog] = useState(false);
    const [comment, setComment] = useState('');
    const [openToast, setOpenToast] = useState(false);

    const [investigationList, setInvestigationList] = useState();
    const [patientData, setPatientData] = useState();
    const [selectedInvestigation, setSelectedInvestigation] = useState();
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [doctorName, setDoctorName] = useState('---');

    const componentRef = useRef();
    const [printCmd, setPrintCmd] = useState();

    //Methods
    const onPrintComplete = () => {
        setPrintCmd(false);
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: onPrintComplete
    });

    const calculateTotal = () => {
        if (investigationList != undefined || investigationList != null) {
            setTotalAmount(investigationList.reduce((amount, charges) => amount + charges.charges, 0));
        }
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getInvestigationList = () => {
        getInvestigationItemsListByPatientAndStatus(searchParams.get('Id'))
            .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                    setDoctorName(response.data.doctorName);
                    if (role === 2) {
                        let data = response.data.investigationItems.filter((item) => {
                            return item.status != 0;
                        });
                        setInvestigationList(data);
                    } else {
                        setInvestigationList(response.data.investigationItems);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getPatientDetails = () => {
        getPatientById(searchParams.get('Id'))
            .then((response) => {
                if (response.status === 200) {
                    setPatientData(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const changeStatus = (id, status) => {
        statusUpdateInvestigationItems(id, status, userId, paymentAmount)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setOpenToast(true);
                    setOpenPaidDialog(false);
                    setOpenCompleteDialog(false);
                    setOpenCancelDialog(false);
                    getInvestigationList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handlePaidCancel = () => {
        setOpenPaidDialog(false);
    };

    const handleCommentCancel = () => {
        setOpenCommentDialog(false);
    };

    const handleCompleteCancel = () => {
        setOpenCompleteDialog(false);
    };

    const handleCancelCancel = () => {
        setOpenCancelDialog(false);
    };

    useEffect(() => {
        getInvestigationList();
        getPatientDetails();
    }, []);

    useEffect(() => {
        calculateTotal();
    }, [investigationList]);

    useEffect(() => {
        if (printCmd) {
            handlePrint();
        }
    });

    useEffect(() => {
        setComment(selectedInvestigation?.comment);
    }, [selectedInvestigation]);

    return (
        <>
            <Card>
                <CardHeader
                    title="Investigation Details"
                    action={
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<PrintIcon />}
                                onClick={() => {
                                    setPrintCmd(true);
                                }}
                            >
                                Print
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<RefreshIcon />}
                                onClick={() => {
                                    getInvestigationList();
                                    getPatientDetails();
                                }}
                                sx={{ marginLeft: 2 }}
                            >
                                Refresh
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<ArrowBackIcon />}
                                onClick={() => {
                                    navigate(-1);
                                }}
                                sx={{ marginLeft: 2 }}
                            >
                                Back
                            </Button>
                        </>
                    }
                />
                <Divider />
                <CardContent>
                    {patientData != null || patientData != undefined ? (
                        <>
                            <Grid container direction="row" sx={{ paddingX: 1 }}>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        UHID
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }}>: {patientData.uhid}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        Patient Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }}>
                                        : {patientData.patientMaster.firstName} {patientData.patientMaster.middleName}{' '}
                                        {patientData.patientMaster.lastName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        Patient Age:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }}>: {calculateAge(patientData.patientMaster.birthDate)}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        Patient Gender:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }}>: {patientData.patientMaster.genderDisplay}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        Patient Address:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }}>
                                        : {patientData.patientMaster.address}, {patientData.patientMaster.city}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        Mobile No.:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }}>: {patientData.patientMaster.mobileNumber}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} align="left">
                                    <Typography variant="h5" sx={{ mb: '3px' }}>
                                        Doctor's Name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} align="left">
                                    <Typography sx={{ mb: '3px' }} variant="h5" color="primary.dark">
                                        : {doctorName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}

                    <div style={{ display: 'none' }}>
                        <InvestigationsPrint
                            ref={componentRef}
                            patientData={patientData}
                            investigationData={investigationList}
                            doctorName={doctorName}
                            totalAmount={totalAmount}
                        />
                    </div>

                    {investigationList != undefined || investigationList != null ? (
                        <>
                            {investigationList.length > 0 ? (
                                <Grid sx={{ mt: 2 }}>
                                    <TableContainer>
                                        <Table sx={{ border: 'solid', borderWidth: 1, borderColor: 'divider' }}>
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableRow>
                                                    <TableCell>Sr. No.</TableCell>
                                                    <TableCell>Investigation Name</TableCell>
                                                    <TableCell>Investigation Date</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="left">Charges</TableCell>
                                                    <TableCell align="left">Comments</TableCell>
                                                    <TableCell align="left">Status</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {investigationList?.map((row, index) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">{new Date(row.createDate).toLocaleDateString()}</TableCell>
                                                        <TableCell align="left">{row.typeDisplay}</TableCell>
                                                        <TableCell align="left">{row.charges}</TableCell>
                                                        <TableCell align="left">{row.comment}</TableCell>
                                                        <TableCell align="left">
                                                            {row.status === 0 && (
                                                                <Typography variant="h5" color="error">
                                                                    {row.statusDisplay}
                                                                </Typography>
                                                            )}
                                                            {row.status === 1 && (
                                                                <Stack>
                                                                    <Typography variant="h5" color="primary">
                                                                        {row.statusDisplay}
                                                                    </Typography>
                                                                    <Typography variant="caption">Payment Collected By:</Typography>
                                                                    <Typography variant="caption">
                                                                        {row.paymentCollectedUserName}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        On: {new Date(row.paymentDate).toLocaleDateString('hi-IN')}
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                            {row.status === 2 && (
                                                                <Stack>
                                                                    <Typography variant="h5" color="success.dark">
                                                                        {row.statusDisplay}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        Completed By: {row.completedByUserName}
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        On: {new Date(row.completionDate).toLocaleDateString('hi-IN')}
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                            {row.status === 3 && (
                                                                <Typography variant="h5" color="secondary">
                                                                    {row.statusDisplay}
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Comment">
                                                                <Button
                                                                    onClick={() => {
                                                                        setSelectedInvestigation(row);
                                                                        setOpenCommentDialog(true);
                                                                    }}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    sx={{ marginRight: 1 }}
                                                                >
                                                                    <CommentIcon />
                                                                </Button>
                                                            </Tooltip>
                                                            {role === 2 ? (
                                                                <>
                                                                    {row.status === 1 && (
                                                                        <Button
                                                                            onClick={() => {
                                                                                setSelectedInvestigation(row);
                                                                                setOpenCompleteDialog(true);
                                                                            }}
                                                                            size="small"
                                                                            variant="contained"
                                                                        >
                                                                            Mark As Complete
                                                                        </Button>
                                                                    )}
                                                                    {row.status === 2 && (
                                                                        <Button
                                                                            onClick={() => {
                                                                                setSelectedInvestigation(row);
                                                                                setOpenCompleteDialog(true);
                                                                            }}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        >
                                                                            Undo Complete
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {row.status === 0 && (
                                                                        <>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setPaymentAmount(row.charges);
                                                                                    setSelectedInvestigation(row);
                                                                                    setOpenPaidDialog(true);
                                                                                }}
                                                                                size="small"
                                                                                variant="contained"
                                                                            >
                                                                                Mark As Paid
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setSelectedInvestigation(row);
                                                                                    setOpenCancelDialog(true);
                                                                                }}
                                                                                size="small"
                                                                                sx={{ marginLeft: 1 }}
                                                                                variant="outlined"
                                                                            >
                                                                                Mark As Cancelled
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                    {row.status === 1 && (
                                                                        <Button
                                                                            onClick={() => {
                                                                                setSelectedInvestigation(row);
                                                                                setOpenPaidDialog(true);
                                                                            }}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        >
                                                                            Undo Paid
                                                                        </Button>
                                                                    )}
                                                                    {row.status === 3 && (
                                                                        <Button
                                                                            onClick={() => {
                                                                                setSelectedInvestigation(row);
                                                                                setOpenCancelDialog(true);
                                                                            }}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        >
                                                                            Undo Cancel
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid sx={{ mt: 2 }}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="h4" sx={{ mb: '3px' }}>
                                                Total Amount:
                                            </Typography>
                                            <Typography variant="h4" color="primary.main" sx={{ mb: '3px' }}>
                                                ₹ {totalAmount}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    {investigationList.length < 1 ? (
                                        <>
                                            <Grid align="center" sx={{ padding: 5 }}>
                                                <Typography variant="h5">No Investigations Found...</Typography>
                                            </Grid>
                                        </>
                                    ) : null}
                                </Grid>
                            ) : (
                                <Typography sx={{ textAlign: 'center', variant: 'h4', margin: 4 }}>
                                    There are no investigations in the list.
                                </Typography>
                            )}
                        </>
                    ) : null}
                    <Divider sx={{ marginY: 5 }} />
                    {patientData != null || patientData != undefined ? <Report patient={patientData} /> : null}
                </CardContent>
            </Card>

            <Dialog open={openCommentDialog} onClose={handleCommentCancel} fullWidth>
                <DialogTitle fontSize={16}>Comments</DialogTitle>
                <DialogContent>
                    <TextField
                        value={comment}
                        multiline
                        fullWidth
                        minRows={3}
                        placeholder="add your comments here..."
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            let data = { ...selectedInvestigation, comment: comment };
                            console.log(data);
                            updateInvestigationItems(data.id, data)
                                .then((response) => {
                                    if (response.status == 200) {
                                        getInvestigationList();
                                        handleCommentCancel();
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }}
                        variant="contained"
                    >
                        Submit
                    </Button>
                    <Button onClick={handleCommentCancel} variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openPaidDialog} onClose={handlePaidCancel} fullWidth>
                <DialogTitle fontSize={16}>Change Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedInvestigation?.status === 0
                            ? 'Are you sure you want to mark this investigation as paid?'
                            : 'Are you sure you want to undo the paid action?'}
                    </DialogContentText>
                    {selectedInvestigation?.status === 0 && (
                        <TextField
                            value={paymentAmount}
                            type="number"
                            label="Amount Collected"
                            sx={{ marginTop: 3 }}
                            onChange={(e) => {
                                setPaymentAmount(e.target.value);
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (selectedInvestigation?.status === 0) {
                                changeStatus(selectedInvestigation?.id, 1);
                            } else {
                                changeStatus(selectedInvestigation?.id, 0);
                            }
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button onClick={handlePaidCancel} variant="outlined">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCompleteDialog} onClose={handleCompleteCancel} fullWidth>
                <DialogTitle fontSize={16}>Change Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedInvestigation?.status === 1
                            ? 'Are you sure you want to mark this investigation as complete?'
                            : 'Are you sure you want to undo the complete action?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (selectedInvestigation?.status === 1) {
                                changeStatus(selectedInvestigation?.id, 2);
                            } else {
                                changeStatus(selectedInvestigation?.id, 1);
                            }
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleCompleteCancel} variant="outlined">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancelDialog} onClose={handleCancelCancel} fullWidth>
                <DialogTitle fontSize={16}>Change Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedInvestigation?.status === 0
                            ? 'Are you sure you want to mark this investigation as cancelled?'
                            : 'Are you sure you want to undo the cancel action?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (selectedInvestigation?.status === 0) {
                                changeStatus(selectedInvestigation?.id, 3);
                            } else {
                                changeStatus(selectedInvestigation?.id, 0);
                            }
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleCancelCancel} variant="outlined">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert
                    onClose={handleCloseToast}
                    severity="success"
                    sx={{ width: '100%', color: 'white', backgroundColor: theme.palette.success.dark }}
                >
                    Data updated succesfully!!
                </Alert>
            </Snackbar>
        </>
    );
};

export default InvestigationDetails;
