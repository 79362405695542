import React from 'react';
import useAxiosAuth from '../hooks/useAxiosAuth';
import { handleResponse, handleError } from './response';

const usePatientReportService = () => {
    const axiosAuth = useAxiosAuth();
    const getReportById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/PatientReports/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getReportByPatientId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/PatientReports/list-byPatient?patientId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateReport = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/PatientReports?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteReport = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/PatientReports?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addReport = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/PatientReports`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const uploadReport = async (id, data) => {
        try {
            const response = await axiosAuth.post(`/api/PatientReports/upload-patientReport?Id=${id}`, data);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getReportById, getReportByPatientId, updateReport, deleteReport, addReport, uploadReport };
};

export default usePatientReportService;
