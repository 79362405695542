//Third party imports
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Project imports
import layoutReducer from './layoutSlice';
import userReducer from './userSlice';
import generalReducer from './generalSlice';

const persistConfig = {
    key: 'root',
    version: 1,
    storage
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
    reducer: {
        layoutReducer: layoutReducer,
        userReducer: persistedUserReducer,
        generalReducer: generalReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});
