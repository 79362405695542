// assets
import { IconUsers, IconBed } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconBed
};

// ==============================|| IPDSTAFF MENU ITEMS ||============================== //

const ipdStaffMenuItems = [
    {
        id: 'patients-section',
        type: 'group',
        children: [
            {
                id: 'patients',
                title: 'Patients',
                type: 'item',
                url: '/patients',
                icon: icons.IconUsers,
                breadcrumbs: false
            },
            {
                id: 'filtered-patients',
                title: 'Filtered Patients',
                type: 'item',
                url: '/filtered-patients',
                icon: icons.IconUsers,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'ipd-main-pages',
        type: 'group',
        children: [
            {
                id: 'ipd',
                title: 'IPD',
                type: 'collapse',
                icon: icons.IconBed,
                breadcrumbs: false,
                children: [
                    {
                        id: 'ipd-overview',
                        title: 'IPD Overview',
                        type: 'item',
                        url: '/ipd-overview',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
];

export default ipdStaffMenuItems;
