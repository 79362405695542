import { useRoutes } from 'react-router-dom';
import { useReducer, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import config from 'config';
import InvestigationDetails from 'views/investigations/InvestigationDetails';

// login option 3 routing
const Login = Loadable(lazy(() => import('views/login')));
const ForgotPassword = Loadable(lazy(() => import('views/login/forgot-password')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// patient page routing
const PatientList = Loadable(lazy(() => import('views/patients/patient-list')));
const FilteredPatientList = Loadable(lazy(() => import('views/patients/filtered-patient-list')));
const PatientForm = Loadable(lazy(() => import('views/patients/patient-form')));
const PatientDetails = Loadable(lazy(() => import('views/patients/patient-details')));
const BillList = Loadable(lazy(() => import('views/billing/bill-list')));
const BillForm = Loadable(lazy(() => import('views/billing/bill-form')));
const DischargeCards = Loadable(lazy(() => import('views/discharge-cards/discharge-card-list')));
const DischargeCardForm = Loadable(lazy(() => import('views/discharge-cards/discharge-card-form')));
const OpdReceipts = Loadable(lazy(() => import('views/billing/opd-receipt-list')));
const AdvancePayment = Loadable(lazy(() => import('views/billing/advance-payment')));

//!Accounts Investigations
const Investigations = Loadable(lazy(() => import('views/investigations')));

// appointment page routing
const Appointments = Loadable(lazy(() => import('views/appointments/appointments')));
const AppointmentForm = Loadable(lazy(() => import('views/appointments/appointment-form')));
const IPDOverview = Loadable(lazy(() => import('views/ipd/ipd-overview')));
const MyAppointments = Loadable(lazy(() => import('views/appointments/my-appointments')));
const PrescriptionList = Loadable(lazy(() => import('views/prescription-list')));
const BillItems = Loadable(lazy(() => import('views/settings/bill-items')));
const Users = Loadable(lazy(() => import('views/settings/users')));
const ExternalStaff = Loadable(lazy(() => import('views/settings/external-staff')));
const InvestigationItems = Loadable(lazy(() => import('views/settings/investigation-items')));
const OpdReceiptItemMaster = Loadable(lazy(() => import('views/settings/opd-receipt-items')));
const OpdReceiptForm = Loadable(lazy(() => import('views/billing/opd-receipt-form')));

//Opeartion Types
const OperationTypes = Loadable(lazy(() => import('views/settings/operation-types/index')));

const MLCForm = Loadable(lazy(() => import('views/appointments/mlc-form')));
const DrugItems = Loadable(lazy(() => import('views/settings/drug-items')));
const PrescriptionTemplates = Loadable(lazy(() => import('views/settings/prescription-templates')));

//Certificate page routing

const Certificates = Loadable(lazy(() => import('views/certificates/index')));

//Report page routing

const InvestigationReports = Loadable(lazy(() => import('views/reports/InvestigationReports')));
const IpdReports = Loadable(lazy(() => import('views/reports/IPDBillingReports')));
const OpdReports = Loadable(lazy(() => import('views/reports/OPDBillingReports')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, role, displayRole } = useSelector((state) => state.userReducer);

    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: userId > 0 ? <PatientList /> : <Navigate to="/login" />
            },
            {
                path: '/dashboard',
                element: userId > 0 ? <DashboardDefault /> : <Navigate to="/login" />
            },
            {
                path: '/patients',
                element: userId > 0 ? <PatientList /> : <Navigate to="/login" />
            },
            {
                path: '/filtered-patients',
                element: userId > 0 ? <FilteredPatientList /> : <Navigate to="/login" />
            },
            {
                path: '/patient-form',
                element: userId > 0 ? <PatientForm /> : <Navigate to="/login" />
            },
            {
                path: '/patient-details',
                element: userId > 0 ? <PatientDetails /> : <Navigate to="/login" />
            },
            {
                path: '/billing',
                element: userId > 0 ? <BillList /> : <Navigate to="/login" />
            },
            {
                path: '/prescription-list',
                element: userId > 0 ? <PrescriptionList /> : <Navigate to="/login" />
            },
            {
                path: '/bill-form',
                element: userId > 0 ? <BillForm /> : <Navigate to="/login" />
            },
            {
                path: '/advance-payments',
                element: userId > 0 ? <AdvancePayment /> : <Navigate to="/login" />
            },
            {
                path: '/opd-receipts',
                element: userId > 0 ? <OpdReceipts /> : <Navigate to="/login" />
            },
            {
                path: '/opd-receipts-form',
                element: userId > 0 ? <OpdReceiptForm /> : <Navigate to="/login" />
            },
            {
                path: '/discharge-cards',
                element: userId > 0 ? <DischargeCards /> : <Navigate to="/login" />
            },
            {
                path: '/discharge-card-form',
                element: userId > 0 ? <DischargeCardForm /> : <Navigate to="/login" />
            },
            {
                path: '/appointments',
                element: userId > 0 ? <Appointments /> : <Navigate to="/login" />
            },
            {
                path: '/appointment-form',
                element: userId > 0 ? <AppointmentForm /> : <Navigate to="/login" />
            },
            {
                path: '/my-appointments',
                element: userId > 0 ? <MyAppointments /> : <Navigate to="/login" />
            },

            { path: '/ipd-overview', element: userId > 0 ? <IPDOverview /> : <Navigate to="/login" /> },
            {
                path: '/bill-items',
                element: userId > 0 ? <BillItems /> : <Navigate to="/login" />
            },
            {
                path: '/opd-receipt-item-master',
                element: userId > 0 ? <OpdReceiptItemMaster /> : <Navigate to="/login" />
            },
            {
                path: '/drug-items',
                element: userId > 0 ? <DrugItems /> : <Navigate to="/login" />
            },
            {
                path: '/users',
                element: userId > 0 ? <Users /> : <Navigate to="/login" />
            },
            {
                path: '/external-staff',
                element: userId > 0 ? <ExternalStaff /> : <Navigate to="/login" />
            },

            {
                path: '/ipd-overview',
                element: userId > 0 ? <IPDOverview /> : <Navigate to="/login" />
            },
            {
                path: '/mlc-form',
                element: userId > 0 ? <MLCForm /> : <Navigate to="/login" />
            },
            {
                path: '/certificates',
                element: userId > 0 ? <Certificates /> : <Navigate to="/login" />
            },
            {
                path: '/prescription-templates',
                element: userId > 0 ? <PrescriptionTemplates /> : <Navigate to="/login" />
            },
            {
                path: '/investigation-items',
                element: userId > 0 ? <InvestigationItems /> : <Navigate to="/login" />
            },
            {
                path: '/investigations',
                element: userId > 0 ? <Investigations /> : <Navigate to="/login" />
            },
            {
                path: '/investigation-details',
                element: userId > 0 ? <InvestigationDetails /> : <Navigate to="/login" />
            },
            {
                path: '/operation-types',
                element: userId > 0 ? <OperationTypes /> : <Navigate to="/login" />
            },
            {
                path: '/investigation-reports',
                element: userId > 0 ? <InvestigationReports /> : <Navigate to="/login" />
            },
            {
                path: '/opd-reports',
                element: userId > 0 ? <OpdReports /> : <Navigate to="/login" />
            },
            {
                path: '/ipd-reports',
                element: userId > 0 ? <IpdReports /> : <Navigate to="/login" />
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
